import * as types from '../mutation-types'

export const performCheckIn = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'performCheckIn')

  return new Promise((resolve, reject) => {
    model
      .performCheckIn({ form })
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'performCheckIn')

        dispatch('promoterCampaignShareShips/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('promoterCampaignPeriodShares/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: performCheckIn,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
